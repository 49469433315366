import ApiService from '@/common/api.service';

const resource = 'offer';

const OfferevaluationService = {
    async getErfolgsquote(params) {
        return ApiService.query(resource + '/evaluation/erfolgsquote', params);
    },
    async getAngebotszeiten(params) {
        return ApiService.query(resource + '/evaluation/angebotszeiten', params);
    },
    async getKtbAuswertung(params) {
        return ApiService.query(resource + '/evaluation/ktbauswertung', params);
    },
    async getGesrpaechsresultat(params) {
        return ApiService.query(resource + '/evaluation/gespraechsresultat', params);
    },
    async getErhaltenUeber(params) {
        return ApiService.query(resource + '/evaluation/erhaltenueber', params);
    },
};

export default OfferevaluationService;
