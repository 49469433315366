export function roleEnum() {
    return {
        ROLE_LEITER_FERTIGUNG: 12,
        ROLE_FERTIGUNG: 11,
        ACCEPTOR_LITE_ROLE: 10,
        ROLE_SERVICE_TECHNIKER: 9,
        ROLE_FILIALE: 8,
        ROLE_LAGER: 7,
        PROJECT_ONLY_ROLE: 6,
        PROJECT_ROLE: 5,
        ACCEPTOR_ROLE: 4,
        STANDARD_ROLE: 3,
        LIST_ADMIN_ROLE: 2,
        ADMIN_ROLE: 1,
    };
}

export function customerRoleEnum() {
    return {
        ROLE_DEFAULT: 1,
        ROLE_REGIONAL_MANAGER: 2,
        ROLE_CONTROL: 3,
    };
}

export function departmentEnum() {
    return {
        OFM: 5,
        KTB: 4,
        OPM: 3,
        EL: 2,
        NO_DEPARTMENT: 1,
    };
}

export function customerEnum() {
    return {
        HOFER: 1,
        NO_CUSTOMER: 0,
    };
}

export function orderStatusEnum() {
    return {
        CREATED: 'CREATED',
        AKT_KW_VKID: 'AKT_KW_VKID',
        AKT_KW: 'AKT_KW',
        MONTAGE: 'MONTAGE',
        MONTAGE_ABGESCHLOSSEN: 'MONTAGE_ABGESCHLOSSEN',
        ZIVI_BESTELLT: 'ZIVI_BESTELLT',
        ZIVI_MANGEL: 'ZIVI_MANGEL',
        ZIVI_ERLEDIGT: 'ZIVI_ERLEDIGT',
        VERRECHNET: 'VERRECHNET',
    };
}

export function ampelEnum() {
    return {
        GREEN: 'green',
        ORANGE: 'orange',
        RED: 'red',
        BLACK: 'black',
    };
}

export function offerDepartmentEnum() {
    return {
        OFFER: 'offer',
        OPM: 'opm',
        SPAREPARTS: 'spareparts',
    };
}

export function bookStatusEnum() {
    return {
        STATUS_INITIAL: 'STATUS_INITIAL',
        STATUS_APPROVING: 'STATUS_APPROVING',
        STATUS_APPROVED: 'STATUS_APPROVED',
        STATUS_INSPECTED: 'STATUS_INSPECTED',
        STATUS_EXPIRED: 'STATUS_EXPIRED',
        STATUS_FAILURE: 'STATUS_FAILURE',
        STATUS_TEMPLATE: 'STATUS_TEMPLATE',
    };
}

export function timeTypeEnum() {
    return {
        PROFILBEARBEITUNG: 'profilbearbeitung',
        ANTRIEB: 'antrieb',
        VERGLASEN: 'verglasen',
        VERSAND: 'versand',
        SONSTIGES: 'sonstiges',
    };
}

export function offerTypeEnum() {
    return {
        AUT: 'aut',
        BAU: 'bau',
    };
}

export function offerTalkResultEnum() {
    return {
        ANGEBOT_IST_BEAUFTRAGT: 'angebot_ist_beauftragt',
        ANGEBOT_WAR_NICHT_VERSTAENDLICH: 'angebot_war_nicht_verstaendlich',
        ABSAGE_PREIS_WAR_ZU_HOCH: 'absage_preis_war_zu_hoch',
        ABSAGE_MITBEWERB_HAT_AUFTRAG_ERHALTEN: 'absage_mitbewerb_hat_auftrag_erhalten',
        ABSAGE_WIRD_NICHT_REALISIERT: 'absage_wird_nicht_realisiert',
        ABSAGE_SONSTIGES: 'sonstiges_talk_result',
    };
}

export function offerReceiveEnum() {
    return {
        TECHNIKER: 'techniker',
        KTB: 'ktb',
        KUNDE: 'kunde',
        EINSATZLEITUNG: 'einsatzleitung',
        BUCHUNG_VERRECHNUNG: 'buchung_verrechnung',
        NEUANLAGENGESCHAEFT: 'neuanlagengeschaeft',
        SONSTIGES: 'sonstiges',
    };
}

export function civilAcceptanceEnum() {
    return {
        KEINE_ERFORDERLICH: 'keine_abnahme_erforderlich',
        ZIVI_MUSS_BESTELLT_WERDEN: 'zivi_muss_bestellt_werden',
        ZIVI_WURDE_BESTELLT: 'zivi_wurde_bestellt',
        ABNAHMEGUTACHTEN_ERHALTEN: 'abnahmegutachten_erhalten',
    };
}
