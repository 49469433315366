import ApiService from '@/common/api.service';
import Toast from '@/common/toast';

const resource = 'offer';

const OfferService = {
    async get(slug) {
        return ApiService.get(resource, slug);
    },
    async query(params) {
        return ApiService.query(resource, params);
    },
    async create(params) {
        let promise = null;
        try {
            promise = await ApiService.post(resource, params);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async update(slug, params) {
        let promise = null;
        try {
            promise = await ApiService.update(resource, slug, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async sendKtbReminderMail(params) {
        return ApiService.post(resource + '/sendktbremindermail', params.params);
    },
    async getFile(blobId) {
        const path = 'donwloadAngebotsanhang/' + blobId;
        return ApiService.getPdf(resource, path);
    },
    async getUpdatedAt(slug) {
        return ApiService.get(resource + '/get/updatedAt', slug);
    },
};

export default OfferService;
