import Vue from 'vue';
import * as _ from 'lodash';
import OfferService from '@/app/offer/offer.service';
import { offerDepartmentEnum } from '@/shared/enum';
import download from 'downloadjs';

const getDefaultItem = () => ({
    _id: null,
    receivedVia: null,
    csnr: null,
    receivedDate: null,
    receivedFrom: null,
    debitor: null,
    tp: null,
    objekt: null,
    objektdaten: null,
    bauObjektNr: null,
    plz: null,
    contactPerson: null,
    customerEmail: null,
    contactPersonTelNr: null,
    opmUser: null,
    ofmUser: null,
    status: null,
    deleteFilesDate: null,
    abteilung: {
        autSchieber: false,
        autDreher: false,
        bau: false,
        umbau: false,
        export: false,
    },
    ktb: null,
    ampel: null,
    notes: [],
    files: [],
    aktuelle_abteilung: offerDepartmentEnum.OFFER,
    angebot: {
        offerNr: null,
        offerDate: null,
        offerSum: null,
        offerFollowUpDate: null,
        talkResult: null,
        notes: [],
    },
    opm: {
        orderFollowUpDate: null,
        ampel: null,
        orderId: null,
        status: null,
        notes: [],
    },
    ersatzteile: {
        orderNumber: null,
        orderDate: null,
        orderSum: null,
        civilAcceptance: null,
        implementationDate: null,
        freigabeZurVerrechnung: null,
        invoiceValue: null,
        notes: [],
    },
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: [],
    currentPage: 1,
    totalPages: 1,
});

const offer = {
    namespaced: true,
    state: Object.assign({}, getDefaultState()),
    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        updateCurrentPage(state, data) {
            Vue.set(state, 'currentPage', data);
        },
        updateTotalPages(state, data) {
            Vue.set(state, 'totalPages', data);
        },
        clear(state) {
            Vue.set(state, 'item', JSON.parse(JSON.stringify(getDefaultState().item)));
            Vue.set(state, 'list', JSON.parse(JSON.stringify(getDefaultState().list)));
        },
    },
    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async create(context, client_data) {
            const { data } = await OfferService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            const { data } = await OfferService.update(client_data.offer._id, client_data);
            context.commit('update', data);
            return data;
        },
        async query(context, params) {
            const { data } = await OfferService.query(params);
            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);
            let totalPages = data.pages;
            context.commit('updateTotalPages', _.ceil(totalPages));
            context.commit('updateList', data);
            return data;
        },
        async get(context, id) {
            const { data } = await OfferService.get(id);
            context.commit('update', data);
            return data;
        },
        async updateOffer(context, client_data) {
            const offer = client_data;
            context.commit('update', offer);
            return offer;
        },
        async getFile(context, client_data) {
            const data = await OfferService.getFile(client_data.blobId);
            let serverFileName = '';
            if (data.headers['content-disposition']) {
                if (data.headers['content-disposition'].split('filename=')[1]) {
                    serverFileName = data.headers['content-disposition'].split('filename=')[1];
                }
            }
            let contentType = 'application/pdf';
            if (data.headers['content-type']) {
                contentType = data.headers['content-type'];
            }
            let fileName = 'Angebotsanhang.txt';
            if (serverFileName !== '' || serverFileName != null) {
                fileName = serverFileName;
            }
            download(new Blob([data.data]), fileName, contentType);
        },
        async getUpdatedAt(context, id) {
            const { data } = await OfferService.getUpdatedAt(id);
            return data;
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        list(state) {
            return state.list;
        },
        currentPage(state) {
            return state.currentPage;
        },
        totalPages(state) {
            return state.totalPages;
        },
    },
};
export { getDefaultItem };
export default offer;
