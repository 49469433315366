import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import JwtService from '@/common/jwt.service';
import auth from '@/app/auth/auth.helpers';
import store from '@/store';
import ConfigService from '@/common/config.service';

const ApiService = {
    //see https://medium.com/@zitko/structuring-a-vue-project-authentication-87032e5bfe16
    _401interceptor: null,
    apiUrl() {
        return ConfigService.getConfigIfExists('backendBaseUrl');
    },

    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = this.apiUrl();

        Vue.axios.defaults.headers.common['Cache-Control'] = 'no-cache';
        Vue.axios.defaults.headers.common['Pragma'] = 'no-cache';
        Vue.axios.defaults.headers.common['Expires'] = 'Sat, 01 Jan 2000 00:00:00 GMT';
        this.useInterceptor();

        if (JwtService.getToken() != null) {
            this.setJwtHeader();
            this.setMandantHeader();
        }
        this.mount401Interceptor();
    },

    useInterceptor() {
        axios.interceptors.response.use(
            function (response) {
                store.dispatch('common/updateLoader', false);
                return response;
            },
            function (error) {
                store.dispatch('common/updateLoader', true);
                return Promise.reject(error);
            }
        );
    },

    setJwtHeader() {
        Vue.axios.defaults.headers.common['Authorization'] = `${JwtService.getToken()}`;
    },

    setMandantHeader() {
        Vue.axios.defaults.headers.common['X-pruefbuch-M'] = JwtService.getMandant();
    },

    mount401Interceptor() {
        this._401interceptor = Vue.axios.interceptors.response.use(
            (response) => response,
            async (error) => {
                if (error.request) {
                    console.log('Error status code:', error.request.status);
                }
                if (error.request && error.request.status === 401) {
                    // Refresh the token and retry the request with the new token
                    return auth
                        .refreshToken()
                        .then(() => {
                            // change the request config's authorization header
                            error.config.headers['Authorization'] = `${JwtService.getToken()}`;
                            return axios.request(error.config);
                        })
                        .catch((error) => {
                            console.log('refreshToken error', error);
                            auth.logout();
                        });
                }
                // If the error was not 401, just reject it as is
                throw error;
            }
        );
    },

    removeJwtHeader() {
        delete Vue.axios.defaults.headers.common['Authorization'];
    },

    removeMandantHeader() {
        delete Vue.axios.defaults.headers.common['X-pruefbuch-M'];
    },

    unmount401Interceptor() {
        // Eject the interceptor
        Vue.axios.interceptors.response.eject(this._401interceptor);
    },

    cleanUp() {
        this.removeJwtHeader();
        this.removeMandantHeader();
        this.unmount401Interceptor();
    },

    query(resource, params) {
        return Vue.axios.get(resource, params).catch((error) => {
            throw new Error(`[pruefbuch] ApiService ${error}`);
        });
    },

    get(resource, slug = '') {
        return Vue.axios.get(`${resource}/${slug}`).catch((error) => {
            throw new Error(`[pruefbuch] ApiService ${error}`);
        });
    },

    getCustom(path) {
        return Vue.axios.get(`${path}`).catch((error) => {
            throw new Error(`[pruefbuch] ApiService ${error}`);
        });
    },

    getPdf(resource, slug = '') {
        return Vue.axios
            .get(`${resource}/${slug}`, {
                responseType: 'arraybuffer',
            })
            .catch((error) => {
                throw new Error(`[pruefbuch] ApiService ${error}`);
            });
    },

    post(resource, params) {
        return Vue.axios.post(`${resource}`, params);
    },

    postAndResultPdf(resource, params) {
        return Vue.axios.post(`${resource}`, params, {
            responseType: 'arraybuffer',
        });
    },

    changeResetPasswort(resource, params) {
        return Vue.axios.post(`${resource}`, params, {
            // headers: { 'Content-Type': 'text/plain' }
        });
    },

    update(resource, slug, params) {
        return Vue.axios.put(`${resource}/${slug}`, params);
    },

    updateAndResultPdf(resource, slug, params) {
        return Vue.axios.put(`${resource}/${slug}`, params, {
            responseType: 'arraybuffer',
        });
    },

    put(resource, params) {
        return Vue.axios.put(`${resource}`, params);
    },

    fileUpload(resource, file, additionalFormData) {
        const formData = new FormData();
        for (let key in additionalFormData) {
            formData.append(key, additionalFormData[key]);
        }
        formData.append('file', file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        return Vue.axios.post(`${resource}`, formData, config);
    },

    delete(resource, slug) {
        return Vue.axios.delete(`${resource}/${slug}`).catch((error) => {
            throw new Error(`[pruefbuch] ApiService ${error}`);
        });
    },
};

export default ApiService;
