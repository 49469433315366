import Vue from 'vue';
import OrderprocessingService from './orderprocessing.service';
import * as _ from 'lodash';

const getDefaultVkid = () => ({
    belegdatum: null,
    betrag: null,
    vergabepreis: null,
    innendienst: null,
    ad: null,
    warenbtermin_date: null,
    geplante_montage_date: null,
    fixtermin: null,
    bemerkungen: [],
    zivi: null,
    vorverrechnet: null,
    verrechnet: null,
    montage_tage: null,
    kalkulierte_min: null,
    kalkulierte_std: null,
    bestellnummer: null,
    ampel: null,
    akt_kw: null,
    verrechneteMaw: null,
});

const getDefaultEl = () => ({
    service_id: null,
    akt_kw: null,
    benoetigte_min: null,
    bemerkungen: [],
    montage: [],
    montagedatum: null,
    ampel: null,
});

const getDefaultLogistik = () => ({
    lieferungen: [],
});

const getDefaultItem = () => ({
    _id: null,
    objekt: null,
    tp: null,
    abnr: null,
    csnr: null,
    objektdaten: null,
    objektnummer: null,
    kunde: null,
    antriebe: [],
    antriebeOther: [],
    bemerkungen: [],
    anzahl_bemerkungen_bei_uebergabe: 0,
    leistungsart: {
        ibn: false,
        maw: false,
        mon: false,
        okl: false,
        rep: false,
        ser: false,
        umr: false,
    },
    abteilung: {
        autSchieber: false,
        autDreher: false,
        bau: false,
        umbau: false,
        export: false,
    },
    aktuelle_abteilung: 1,
    ansprechpartner_elektrik: {
        bezeichnung: null,
        email: null,
        tel: null,
    },
    ansprechpartner_baustelle: {
        bezeichnung: null,
        email: null,
        tel: null,
    },
    ansprechpartner_kunde: {
        bezeichnung: null,
        email: null,
        tel: null,
    },
    entscheidungstraeger: {
        bezeichnung: null,
        email: null,
        tel: null,
    },
    vkid: getDefaultVkid(),
    el: getDefaultEl(),
    logistik: getDefaultLogistik(),
    maw: [],
    gesamt_ampel: null,
    status: null,
    statusLastModified: null,
    offerId: null,
    active: true,
});

const getDefaultLastSearch = () => ({
    type: null,
    value: null,
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: [],
    currentPage: 1,
    totalPages: 1,
    lastSearch: getDefaultLastSearch(),
});

const orderprocessing = {
    namespaced: true,
    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        updateCurrentPage(state, data) {
            Vue.set(state, 'currentPage', data);
        },
        updateTotalPages(state, data) {
            Vue.set(state, 'totalPages', data);
        },
        updateLastSearch(state, data) {
            Vue.set(state, 'lastSearch', data);
        },
        clear(state) {
            Vue.set(state, 'item', JSON.parse(JSON.stringify(getDefaultState().item)));
            Vue.set(state, 'list', JSON.parse(JSON.stringify(getDefaultState().list)));
        },
        clearLastSearch(state) {
            Vue.set(state, 'lastSearch', JSON.parse(JSON.stringify(getDefaultState().lastSearch)));
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        clearLastSearch({ commit }) {
            commit('clearLastSearch');
        },
        updateLastSearch(context, lastSearch) {
            context.commit('updateLastSearch', lastSearch);
        },
        async create(context, client_data) {
            const { data } = await OrderprocessingService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            const { data } = await OrderprocessingService.update(client_data.order._id, client_data);
            context.commit('update', data);
            return data;
        },
        async getUpdatedAt(context, id) {
            const { data } = await OrderprocessingService.getUpdatedAt(id);
            return data;
        },
        async get(context, id) {
            const { data } = await OrderprocessingService.get(id);
            if (data.ansprechpartner_kunde == null) {
                data.ansprechpartner_kunde = {
                    bezeichnung: null,
                    email: null,
                    tel: null,
                };
            }
            if (data.entscheidungstraeger == null) {
                data.entscheidungstraeger = {
                    bezeichnung: null,
                    email: null,
                    tel: null,
                };
            }
            context.commit('update', data);
            return data;
        },
        async query(context, params) {
            const { data } = await OrderprocessingService.query(params);
            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);
            let totalPages = data.pages;
            context.commit('updateTotalPages', _.ceil(totalPages));
            context.commit('updateList', data);
            return data;
        },
        async delete(context, slug) {
            const { data } = await OrderprocessingService.delete(slug);
            context.commit('updateList', data);
            return data;
        },
        async getOrdersBySerialnummerSearch(context, params) {
            const { data } = await OrderprocessingService.getOrdersBySerialnummerSearch(params);
            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);
            let totalPages = data.pages;
            context.commit('updateTotalPages', _.ceil(totalPages));
            context.commit('updateList', data.docs);
            return data.docs;
        },
        async getOrdersBySearch(context, params) {
            const { data } = await OrderprocessingService.getOrdersBySearch(params);
            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);
            let totalPages = data.pages;
            context.commit('updateTotalPages', _.ceil(totalPages));
            context.commit('updateList', data.docs);
            return data.docs;
        },
        async getOrdersByOpm(context, params) {
            const { data } = await OrderprocessingService.getOrdersByOpm(params);
            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);
            let totalPages = data.pages;
            context.commit('updateTotalPages', _.ceil(totalPages));
            context.commit('updateList', data.docs);
            return data.docs;
        },
        async countOrdersByVkid(context, params) {
            const { data } = await OrderprocessingService.countOrdersByVkid(params);
            return data;
        },
        async getOrdersByEl(context, params) {
            const { data } = await OrderprocessingService.getOrdersByEl(params);
            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);
            let totalPages = data.pages;
            context.commit('updateTotalPages', _.ceil(totalPages));
            context.commit('updateList', data.docs);
            return data.docs;
        },
        async countAllVkidOrders() {
            const { data } = await OrderprocessingService.countAllVkidOrders();
            return data;
        },
        async countOrdersByEl(context, params) {
            const { data } = await OrderprocessingService.countOrdersByEl(params);
            return data;
        },
        async getAllVkidOrders(context, params) {
            const { data } = await OrderprocessingService.getAllVkidOrders(params);
            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);
            let totalPages = data.pages;
            context.commit('updateTotalPages', _.ceil(totalPages));
            context.commit('updateList', data.docs);
            return data.docs;
        },
        async getAllElOrders(context, params) {
            const { data } = await OrderprocessingService.getAllElOrders(params);
            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);
            let totalPages = data.pages;
            context.commit('updateTotalPages', _.ceil(totalPages));
            context.commit('updateList', data.docs);
            return data.docs;
        },
        async getAllAdOrders(context, params) {
            const { data } = await OrderprocessingService.getAllAdOrders(params);
            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);
            let totalPages = data.pages;
            context.commit('updateTotalPages', _.ceil(totalPages));
            context.commit('updateList', data.docs);
            return data.docs;
        },
        async countAllElOrders() {
            const { data } = await OrderprocessingService.countAllElOrders();
            return data;
        },
        async countAllAdOrders() {
            const { data } = await OrderprocessingService.countAllAdOrders();
            return data;
        },
        async countOrdersByAd(context, params) {
            const { data } = await OrderprocessingService.countOrdersByAd(params);
            return data;
        },
        async getOrdersByAd(context, params) {
            const { data } = await OrderprocessingService.getOrdersByAd(params);
            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);
            let totalPages = data.pages;
            context.commit('updateTotalPages', _.ceil(totalPages));
            context.commit('updateList', data.docs);
            return data.docs;
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        list(state) {
            return state.list;
        },
        currentPage(state) {
            return state.currentPage;
        },
        totalPages(state) {
            return state.totalPages;
        },
        lastSearch(state) {
            return state.lastSearch;
        },
    },
};
export { getDefaultItem };
export default orderprocessing;
