import OfferevaluationService from '@/app/offerevaluation/offerevaluation.service';
import Vue from 'vue';

const getDefaultState = () => ({
    // item: getDefaultItem(),
    list: [],
});

const offerevaluation = {
    namespaced: true,
    state: Object.assign({}, getDefaultState()),
    mutations: {
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
    },
    actions: {
        async getErfolgsquote(context, params) {
            const { data } = await OfferevaluationService.getErfolgsquote(params);
            context.commit('updateList', data);
            return data;
        },
        async getAngebotszeiten(context, params) {
            const { data } = await OfferevaluationService.getAngebotszeiten(params);
            context.commit('updateList', data);
            return data;
        },
        async getKtbAuswertung(context, params) {
            const { data } = await OfferevaluationService.getKtbAuswertung(params);
            context.commit('updateList', data);
            return data;
        },
        async getGesrpaechsresultat(context, params) {
            const { data } = await OfferevaluationService.getGesrpaechsresultat(params);
            context.commit('updateList', data);
            return data;
        },
        async getErhaltenUeber(context, params) {
            const { data } = await OfferevaluationService.getErhaltenUeber(params);
            context.commit('updateList', data);
            return data;
        },
    },
};
export default offerevaluation;
